import { decode } from "html-entities";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "../components";
import { Loader } from "../loaders/loader.component";
import { useRunCourse } from "./useRunCourse";
import { LaunchCourseButton } from "./LaunchCourseButton";
import { CourseProgressState } from "./course-progress.enum";
import { Course } from "../courses/courses.types";
import { ParseKeys } from "i18next";
import { isExamUnlockedOrCourseCompleted } from "../utils/course.utils";
import { CourseProgress } from "./course-progress.types";

export type CourseDetailsProps = {
  courseProgress?: CourseProgress;
  course: Course;
  isChildCourse?: boolean;
  onCourseLaunched?: () => void;
  onTakeExam?: () => void;
  onNextCourse?: () => void;
  nextCourseLabel?: ParseKeys;
};

export const CourseDetails: FC<CourseDetailsProps> = (props) => {
  const {
    course,
    courseProgress,
    onTakeExam,
    onNextCourse,
    onCourseLaunched,
    nextCourseLabel,
    isChildCourse,
  } = props;
  const { t } = useTranslation();
  const courseId = course.id;
  const { openCourseWindow } = useRunCourse({
    courseId,
    cascadeProgress: isChildCourse,
    courseProgress,
  });

  const Headline = isChildCourse ? "h3" : "h2";

  if (!course) return <Loader />;

  return (
    <div className="">
      <div className="relative flex flex-col items-center">
        <img
          className="w-full max-h-80 object-cover object-center rounded-md"
          src={course.thumbnailUrl}
          alt={course.title}
        />
        <div className="flex bg-white bg-opacity-30 absolute w-full h-full items-center justify-center">
          <div className="flex flex-col gap-2 items-stretch w-30">
            <LaunchCourseButton
              onNextCourse={onNextCourse}
              nextCourseLabel={nextCourseLabel}
              onLaunchCourse={() => {
                onCourseLaunched?.();
                openCourseWindow(
                  course.scormUrl,
                  courseProgress?.state === CourseProgressState.COMPLETED,
                );
              }}
              onStartExam={onTakeExam}
              courseProgressState={
                courseProgress?.state ?? CourseProgressState.NOTSTARTED
              }
            />
            {isExamUnlockedOrCourseCompleted(courseProgress?.state) && (
              <Button
                type="button"
                className={`rounded-md py-2 text-sm font-semibold leading-6 bg-white bg-opacity-50 backdrop-blur-sm`}
                action={() => openCourseWindow(course.scormUrl)}
                kind="secondary"
                label={t("courseDetail.open")}
              />
            )}
          </div>
        </div>
      </div>
      <div className="w-full flex flex-col gap-8 mt-8">
        {course.description ? (
          <div>
            <Headline className="mt-6 font-semibold">
              {t("courseDetail.learnerCourseDescription")}
            </Headline>
            <div
              dangerouslySetInnerHTML={{
                __html: decode(course.description).trim(),
              }}
              className="text-sm leading-relaxed"
            />
          </div>
        ) : null}
        {course.goal ? (
          <div>
            <Headline>{t("courseDetail.goal")}</Headline>
            <div
              dangerouslySetInnerHTML={{ __html: decode(course.goal).trim() }}
              className="text-sm leading-relaxed"
            />
          </div>
        ) : null}
        <div>
          <Headline>{t("courseDetail.rbp")}</Headline>
          <p className="text-sm">{course.rbp || t("courseDetail.none")}</p>
        </div>
        {course.contributor ? (
          <div>
            <Headline>{t("courseDetail.author")}</Headline>
            <p className="sm:mb-4 text-sm">{decode(course.contributor)}</p>
          </div>
        ) : null}
      </div>
    </div>
  );
};
